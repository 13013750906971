export const extractImageProps = ({ image, title }) => {
  return {
    alt: title,
    source: image,
    title
  }
}

const LISTER_IMAGES_PER_ROW = 4
const AFTER_SECOND_ROW_ELEMENT = 8

export function getImageOptimizationProps(i) {
  return {
    ...(i < LISTER_IMAGES_PER_ROW && { fetchpriority: 'high' }),
    ...(i >= AFTER_SECOND_ROW_ELEMENT && { loading: 'lazy' })
  }
}
